import React from 'react';

const PublicPage = () => {
  return (
    <div>
      <h1>Welcome to the Public Page!</h1>
      <p>This page is accessible to everyone, no login required.</p>
    </div>
  );
};

export default PublicPage;
