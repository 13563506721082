import React, { useState, useEffect } from 'react';
import logoGif from "../assets/Companylogo.png"; // Import your GIF file

const LogoLoader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 7000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  if (!isLoading) {
    return null;
  }

  return (
    <div className={`fixed top-0 left-0 w-full h-full bg-black flex flex-col justify-center items-center z-50`}>
      <img 
        src={logoGif} 
        alt="Loading..." 
        className="w-1/3 sm:w-1/2 h-auto" 
      />
      <h1 className='text-white text-[16px] '>CRM Geni</h1>
      <div className="text-[14px] fixed bottom-0 mb-5 text-sm text-gray-400 text-center">
        Made in INDIA<br/>
        <span>Cyber Space Digital.in</span>
      </div>
    </div>
  );
};

export default LogoLoader;
