import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import LoginPage from "./LoginPage";
import Dashboard from "./routes/Dashboard";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import InvoicesPage from "./routes/invoice/InvoicesPage";
import FullInvoice from "./routes/invoice/FullInvoice";
import ProductsPage from "./routes/ProductsPage";
import ClientsPage from "./routes/ClientsPage";
import DailySpendPage from "./routes/DailySpendPage";
import SettingsPage from "./routes/Settings/SettingsPage";
import CompanyDetails from "./routes/Settings/CompanyDetails";
import Analysis from "./routes/Analysis";
import NotFoundPage from "./components/NotFoundPage"; // Add this import
import GrossProfit from "./routes/GrossProfit/grossprofit";
import MenuPage from "./routes/MenuPage"; // Import your new public page

const ProtectedRoute = ({ element, isLoggedIn }) => {
  return isLoggedIn ? element : <Navigate to="/" />;
};

const App = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  return (
    <>
      {isLoggedIn && <Navbar />} {/* Conditionally render Navbar */}
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/dashboard" /> : <LoginPage />}
        />
        <Route
          path="/menu"
          element={<MenuPage />} // Public route, visible to everyone
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute element={<Dashboard />} isLoggedIn={isLoggedIn} />
          }
        />
        <Route
          path="/invoices"
          element={
            <ProtectedRoute
              element={<InvoicesPage />}
              isLoggedIn={isLoggedIn}
            />
          }
        />
        <Route
          path="/invoice/:id"
          element={
            <ProtectedRoute element={<FullInvoice />} isLoggedIn={isLoggedIn} />
          }
        />
        <Route
          path="/products"
          element={
            <ProtectedRoute
              element={<ProductsPage />}
              isLoggedIn={isLoggedIn}
            />
          }
        />
        <Route
          path="/clients"
          element={
            <ProtectedRoute element={<ClientsPage />} isLoggedIn={isLoggedIn} />
          }
        />
        <Route
          path="/analysis"
          element={
            <ProtectedRoute element={<Analysis />} isLoggedIn={isLoggedIn} />
          }
        />
        <Route
          path="/dailyspend"
          element={
            <ProtectedRoute
              element={<DailySpendPage />}
              isLoggedIn={isLoggedIn}
            />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              element={<SettingsPage />}
              isLoggedIn={isLoggedIn}
            />
          }
        />
        <Route
          path="/companydetails"
          element={
            <ProtectedRoute
              element={<CompanyDetails />}
              isLoggedIn={isLoggedIn}
            />
          }
        />
        <Route
          path="/grossprofit"
          element={
            <ProtectedRoute
              element={<GrossProfit />}
              isLoggedIn={isLoggedIn}
            />
          }
        />
        <Route path="*" element={<NotFoundPage />} /> 
      </Routes>
      {isLoggedIn && <Footer />} {/* Conditionally render Footer */}
    </>
  );
};

export default App;
