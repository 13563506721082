import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { IoTrashSharp, IoCart, IoEyeOutline } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

import {
  INVOICE_CRUD_API,
  CUSTOMERS_CRUD_API,
  PRODUCTS_CRUD_API,
  SETTINGS_CRUD_API,
} from "../../hooks/APIHooks";

const InvoicesPage = () => {
  const [invoices, setInvoices] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(today);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [form, setForm] = useState({
    invoice_number: "",
    invoice_date: today,
    customers_name: "",
    customers_email: "",
    customers_phone: "",
    customers_address: "",
    products: [
      { name: "", quantity: "1", price: "", category: "", description: "" },
    ],
    due_date: today,
    discount: "",
    total_amount: "",
    status: "Unpaid",
  });

  const [editId, setEditId] = useState(null);
  const [message, setMessage] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    fetchInvoices();
    fetchCustomerData();
    fetchProductData();
  }, []);

  useEffect(() => {
    // Filter invoices based on date range
    const filtered = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.invoice_date);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000); // Min date
      const end = endDate ? new Date(endDate) : new Date(8640000000000000); // Max date
      return invoiceDate >= start && invoiceDate <= end;
    });
    setFilteredInvoices(filtered);
  }, [startDate, endDate, invoices]);

  useEffect(() => {
    console.log("Invoices data:", invoices);
    console.log("Customer data:", customerData);
    console.log("Product data:", productData);
  }, [invoices, customerData, productData]);

  useEffect(() => {
    // Calculate total amount whenever products or discount change
    calculateTotalAmount();
  }, [form.products, form.discount]);

  useEffect(() => {
    // Filter invoices based on date range
    const filtered = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.invoice_date);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000); // Min date
      const end = endDate ? new Date(endDate) : new Date(8640000000000000); // Max date
      return invoiceDate >= start && invoiceDate <= end;
    });
    setFilteredInvoices(filtered);
  }, [startDate, endDate, invoices]);

  const fetchCustomerData = async () => {
    try {
      const response = await axios.get(CUSTOMERS_CRUD_API);
      setCustomerData(response.data);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchProductData = async () => {
    try {
      const response = await axios.get(PRODUCTS_CRUD_API);
      setProductData(response.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [id]: value,
    }));
  };

  const handleCustomerNameChange = (e) => {
    const selectedName = e.target.value;
    const selectedCustomer = customerData.find(
      (customer) => customer.name === selectedName
    );

    if (selectedCustomer) {
      setForm({
        ...form,
        customers_name: selectedName,
        customers_email: selectedCustomer.email,
        customers_phone: selectedCustomer.phone,
        customers_address: selectedCustomer.address,
      });
    } else {
      setForm({
        ...form,
        customers_name: selectedName,
        customers_email: "",
        customers_phone: "",
        customers_address: "",
      });
    }
  };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const updatedProducts = [...form.products];

    if (name === "name") {
      const selectedProduct = productData.find(
        (product) => product.name === value
      );
      const price = selectedProduct ? selectedProduct.price : "";
      const category = selectedProduct ? selectedProduct.category : "";
      const description = selectedProduct ? selectedProduct.description : "";

      updatedProducts[index] = {
        ...updatedProducts[index],
        name: value,
        price: price,
        category: category,
        description: description,
      };
    } else {
      updatedProducts[index] = {
        ...updatedProducts[index],
        [name]: value,
      };
    }

    setForm((prevForm) => ({
      ...prevForm,
      products: updatedProducts,
    }));

    // Recalculate total after product change
    calculateTotalAmount(updatedProducts);
  };

  const handleAddProduct = () => {
    setForm((prevForm) => ({
      ...prevForm,
      products: [...prevForm.products, { name: "", quantity: "1", price: "" }],
    }));
  };

  const calculateTotalAmount = (updatedProducts) => {
    const totalAmount = (updatedProducts || form.products).reduce(
      (total, product) => {
        const price = parseFloat(product.price) || 0;
        const quantity = parseInt(product.quantity) || 1;
        return total + price * quantity;
      },
      0
    );

    const discount = parseFloat(form.discount) || 0;
    const totalWithDiscount = totalAmount - discount;

    setForm((prevForm) => ({
      ...prevForm,
      total_amount: totalWithDiscount.toFixed(2),
    }));
  };

  const handleAddOrUpdate = async (e) => {
    e.preventDefault();
    try {
      const formattedProducts = form.products.reduce((acc, product, index) => {
        acc[`Product${index + 1}`] = product.name;
        acc[`Quantity${index + 1}`] = product.quantity;
        acc[`Price${index + 1}`] = product.price;
        return acc;
      }, {});

      const requestData = {
        ...form,
        ...formattedProducts,
      };

      const method = editId ? "PUT" : "POST";
      const url = editId
        ? `${INVOICE_CRUD_API}?id=${editId}`
        : INVOICE_CRUD_API;

      const response = await axios({
        method,
        url,
        data: requestData,
      });

      setMessage(response.data.message || "Invoice saved successfully");
      resetForm();
      fetchInvoices(); // Refresh the list of invoices after adding/updating
    } catch (error) {
      console.error("Error details:", error.response || error.message || error);
      setMessage(
        error.response?.data?.message ||
          "Error saving invoice. Please check the console for details."
      );
    }
  };

  const resetForm = () => {
    setForm({
      invoice_number: "",
      invoice_date: today,
      due_date: today,
      customers_name: "",
      customers_email: "",
      customers_phone: "",
      customers_address: "",
      products: [],
      discount: 0,
      total_amount: 0,
      status: "",
    });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "2.7rem", // Adjust the height as needed
      minHeight: "2.7rem",
      display: "flex",
      alignItems: "center",
    }),
    indicatorSeparator: () => ({
      display: "none", // Removes the line beside the down arrow indicator
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: 4,
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      lineHeight: "2.5rem", // Center text vertically
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      lineHeight: "2.5rem", // Center text vertically
    }),
    placeholder: (provided) => ({
      ...provided,
      lineHeight: "2.5rem", // Center placeholder text vertically
    }),
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  useEffect(() => {
    // Filter invoices based on date range
    const filtered = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.invoice_date);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000); // Min date
      const end = endDate ? new Date(endDate) : new Date(8640000000000000); // Max date
      return invoiceDate >= start && invoiceDate <= end;
    });
    setFilteredInvoices(filtered);
  }, [startDate, endDate, invoices]);

  const fetchInvoices = async () => {
    try {
      // Fetch settings
      const settingsResponse = await axios.get(SETTINGS_CRUD_API);
      const settings = settingsResponse.data;

      // Extract the prefix from settings
      const prefix = settings.invoice_prefix || "";

      // Fetch invoices
      const invoiceResponse = await axios.get(INVOICE_CRUD_API);
      setInvoices(invoiceResponse.data);

      // Generate next invoice number
      if (invoiceResponse.data.length > 0) {
        const lastInvoiceNumber =
          invoiceResponse.data[invoiceResponse.data.length - 1].invoice_number;
        const lastInvoiceDigits = parseInt(
          lastInvoiceNumber.replace(settings.invoice_number, ""),
          10
        );
        const nextInvoiceDigits = (lastInvoiceDigits + 1)
          .toString()
          .padStart(5, "0");
        const nextInvoiceNumber = `${settings.invoice_number}${nextInvoiceDigits}`;

        setForm((prevForm) => ({
          ...prevForm,
          invoice_number: nextInvoiceNumber,
        }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          invoice_number: `${settings.invoice_number}000001`,
        }));
      }
    } catch (error) {
      console.error("Error fetching invoices or settings:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this invoice?")) {
      try {
        await axios.delete(`${INVOICE_CRUD_API}?id=${id}`);
        fetchInvoices();
      } catch (error) {
        console.error("Error deleting invoice:", error);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format as DD-MM-YYYY
  };

  // Table columns definition
  const columns = React.useMemo(
    () => [
      {
        Header: "Invoice Details",
        columns: [
          {
            Header: "Invoice Number",
            accessor: "invoice_number",
            Cell: ({ row }) => (
              <div>
                <div>{row.original.invoice_number}</div>
                <div className="flex items-center space-x-2 mt-2">
                  <Link to={`/invoice/${row.original.id}`}>
                    <button className="bg-violet-700 text-white rounded-md hover:bg-blue-600">
                      <IoEyeOutline className="mx-auto" />
                    </button>
                  </Link>
                  <button
                    onClick={() => handleDelete(row.original.id)}
                    className="bg-red-600  text-white rounded-md hover:bg-red-600 "
                  >
                    <IoTrashSharp className="mx-auto" />
                  </button>
                </div>
              </div>
            ),
          },
          {
            Header: "Invoice Date",
            accessor: "invoice_date",
            Cell: ({ value }) => formatDate(value),
          },
        ],
      },
      {
        Header: "Customer Details",
        columns: [
          { Header: "Customer Name", accessor: "customers_name" },
          {
            Header: "Total Amount",
            accessor: "total_amount",
            Cell: ({ row }) => (
              <div>
                <div className="text-[12px]">{row.original.total_amount} ₹</div>
                <span
                  className={`px-2 py-1 mt-2 rounded-full text-white ${
                    row.original.status === "Paid"
                      ? "bg-green-500"
                      : "bg-red-600"
                  }`}
                >
                  {row.original.status}
                </span>
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  // Table setup
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: filteredInvoices,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className=" max-w-4xl mx-auto mt-5 mb-14">
      <h1 className="text-2xl font-bold  text-center">Invoices</h1>
      <p className="text-sm text-center mb-4">Start Creating your invoice.</p>
      {message && (
        <div className="mb-4 p-4 text-white bg-violet-500 rounded-md">
          {message}
        </div>
      )}
      <form onSubmit={handleAddOrUpdate} className="mb-8 p-4">
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          {/* Left Column */}
          <div className="mb-4">
            <p className="text-sm text-gray-700 mt-2">
              Invoice Number{" "}
              <p className="text-black font-bold">{form.invoice_number}</p>
            </p>

            <label
              htmlFor="invoice_date"
              className="block text-sm font-medium text-gray-700 mt-4"
            >
              Invoice Date
            </label>
            <input
              type="date"
              id="invoice_date"
              value={form.invoice_date}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />

            <label
              htmlFor="due_date"
              className="block text-sm font-medium text-gray-700 mt-4"
            >
              Due Date
            </label>
            <input
              type="date"
              id="due_date"
              value={form.due_date}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>

          {/* Right Column */}
          <div className="mb-4">
            <label
              htmlFor="customers_name"
              className="block text-sm font-medium text-gray-700"
            >
              Customer's Name
            </label>
            <Select
              id="customers_name"
              value={
                customerData.find(
                  (customer) => customer.name === form.customers_name
                )
                  ? { value: form.customers_name, label: form.customers_name }
                  : null
              }
              onChange={(selected) =>
                handleCustomerNameChange({
                  target: { value: selected ? selected.value : "" },
                })
              }
              options={customerData.map((customer) => ({
                value: customer.name,
                label: customer.name,
              }))}
              className="mt-1 block w-full"
              placeholder="Customer"
              styles={customStyles} // Apply custom styles
            />
            <p className="text-sm text-gray-500 mt-2">
              Email: {form.customers_email}
            </p>
            <p className="text-sm text-gray-500">
              Phone: {form.customers_phone}
            </p>
            <p className="text-sm text-gray-500">
              Address: {form.customers_address}
            </p>
            <Link
              to="/clients"
              className="text-violet-600 text-[12px] font-bold mt-1"
            >
              + Add New Customer
            </Link>
          </div>
        </div>

        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Products</h2>
          {form.products.map((product, index) => (
            <div key={index} className="grid grid-cols-3 gap-4 mb-2">
              <div>
                <label
                  htmlFor={`product_name_${index}`}
                  className="block text-sm font-medium text-gray-700"
                >
                  Product Name
                </label>
                <select
                  id={`product_name_${index}`}
                  name="name"
                  value={product.name}
                  onChange={(e) => handleProductChange(index, e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                >
                  <option value="">Select Product</option>
                  {productData.map((product) => (
                    <option key={product.name} value={product.name}>
                      {product.category} - {product.name} - Price :{" "}
                      {product.price}
                    </option>
                  ))}
                </select>
                <p className="text-sm text-gray-500">Price: {product.price}</p>
                <p className="text-sm text-gray-500">
                  Category: {product.category}
                </p>
                <p className="text-sm text-gray-500">
                  Description: {product.description}
                </p>
              </div>

              <div>
                <div>
                  <label
                    htmlFor={`product_price_${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Price
                  </label>
                  <input
                    type="number"
                    id={`product_price_${index}`}
                    name="price"
                    value={product.price}
                    onChange={(e) => handleProductChange(index, e)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    min="1"
                  />

                  <label
                    htmlFor={`product_price_${index}`}
                    className="block text-sm mt-2 font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    id={`product_description_${index}`}
                    name="description"
                    value={product.description}
                    onChange={(e) => handleProductChange(index, e)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:border-violet-500 focus:ring focus:ring-violet-200 focus:ring-opacity-50"
                    min="1"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor={`product_quantity_${index}`}
                  className="block text-sm font-medium text-gray-700"
                >
                  Quantity
                </label>
                <input
                  type="number"
                  id={`product_quantity_${index}`}
                  name="quantity"
                  value={product.quantity}
                  onChange={(e) => handleProductChange(index, e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  min="1"
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddProduct}
            className="mt-2 px-4 py-2 bg-violet-600 text-white rounded-md flex items-center justify-center space-x-2"
          >
            <IoCart className="text-3xl" />
            <span>Add Product</span>
          </button>
        </div>

        <div className="grid grid-cols-3 gap-4 md:grid-cols-4">
          <div className="mb-4">
            <label
              htmlFor="discount"
              className="block text-sm font-medium text-gray-700"
            >
              Discount
            </label>
            <input
              type="number"
              id="discount"
              value={form.discount}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              min="0"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="total_amount"
              className="block text-sm font-medium text-gray-700"
            >
              Total Amount
            </label>
            <input
              type="text"
              id="total_amount"
              value={form.total_amount}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              min="0"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="status"
              value={form.status}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            >
              <option value="">Select Status</option>
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
              <option value="Pending">Pending</option>
            </select>
          </div>
        </div>

        <div className="flex space-x-4">
          <button
            type="submit"
            className="px-4 py-2 bg-black text-white rounded-md"
          >
            {editId ? "Update Invoice" : "Add Invoice"}
          </button>
        </div>
      </form>

      <div className=" mx-auto overflow-x-auto">
        <div className="mb-4 px-2">
          <div className="flex items-center space-x-4">
            <label className="text-sm font-medium text-gray-700">
              Start Date:
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
            <label className="text-sm font-medium text-gray-700">
              End Date:
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
            <button
              onClick={() => {
                setStartDate("");
                setEndDate(today);
              }}
              className="bg-violet-600 text-white p-2 rounded-md hover:bg-violet-700 transition duration-200"
            >
              Clear Filter
            </button>
          </div>
        </div>

        <table
          {...getTableProps()}
          className="min-w-full divide-y divide-gray-200 bg-white shadow-md rounded-md"
        >
          <thead className="bg-gray-100">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {column.render("Header")}
                    <span className="text-gray-400 ml-1">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 py-3 text-sm text-gray-900"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="mt-4 space-y-2">
          <div className="text-sm text-center text-gray-600">
            Page {pageIndex + 1} of {pageOptions.length}
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="bg-violet-700 text-white p-2 rounded-md hover:bg-violet-400 transition duration-200"
            >
              Previous
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="bg-violet-700 text-white p-2 rounded-md hover:bg-violet-400 transition duration-200"
            >
              Next
            </button>
          </div>

          <div className="text-sm text-gray-600 text-center justify-center">
            Go to page:{" "}
            <input
              type="number"
              min="1"
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="w-16 p-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesPage;
